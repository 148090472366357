// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-another-js": () => import("./../../../src/pages/another.js" /* webpackChunkName: "component---src-pages-another-js" */),
  "component---src-pages-definitions-js": () => import("./../../../src/pages/definitions.js" /* webpackChunkName: "component---src-pages-definitions-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insurance-js": () => import("./../../../src/pages/insurance.js" /* webpackChunkName: "component---src-pages-insurance-js" */)
}

